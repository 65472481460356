import React, { useCallback } from 'react';
import * as jose from 'jose';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Async from "react-async";
import Papa from 'papaparse';

import { GoogleOauth2Token, CalendarEvents, CalendarEvent } from './models/GoogleCalendar';
import { Engineer } from './models/Engineer';

import serviceAccount from './serviceAccountProd.json';
// service account must be configured with domain wide delegation
// https://developers.google.com/admin-sdk/directory/v1/guides/delegation

// import moment from 'moment';

const CALENDAR_ID = 'calendar@openbase.co.kr';
const CALENDAR_ACCOUNT_EMAIL = 'calendar@openbase.co.kr';
const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
const MOMENT_TIME_FORMAT = 'HH:00';
const MOMENT_DATETIME_FORMAT = 'YYYY-MM-DD HH:00';
const MOMENT_INPUT_DATETIME_FORMAT = 'YYYY-MM-DDTHH:00';
const MOMENT_INPUT_DATETIME_FORMAT_HUMAN = 'YYYY-MM-DD HH:mm:ss';

function App() {
  const [googleOauth2Token, setGoogleOauth2Token] = useState<GoogleOauth2Token | undefined>();

    // 현재 로그인한 netlify 사용자 저장.
    const [currentNetlifyUser, setCurrentNetlifyUser] = useState<any | null>(null);
  
  const [startDate, setStartDate] = useState<moment.Moment>(moment().startOf('hour'));
  const [endDate, setEndDate] = useState<moment.Moment>(moment().add(2, 'hour').startOf('hour'));
  const [refreshDatetime, setRefreshDatetime] = useState<moment.Moment | null>(null);

  const [loading, setLoading] = useState<Promise<any>|undefined>();
  const [events, setEvents] = useState<CalendarEvents | undefined>();
  
  const [engineers, setEngineers] = useState<Engineer[]>([]);
  const [techSkills, setTechSkills] = useState<string[]>([]);
  const [selectedTechSkills, setSelectedTechSkills] = useState<Set<string>>(new Set());

  const [busyEngineerNames, setBusyEngineerNames] = useState<Set<string>>(new Set());
  const [availableEngineers, setAvailableEngineers] = useState<Engineer[]>([]);
  const [unavailableEngineers, setUnavailableEngineers] = useState<Engineer[]>([]);

  useEffect(() => {
    (async () => {
      // Using OAuth 2.0 for Server to Server Applications - Addendum: Service account authorization without OAuth
      // https://developers.google.com/identity/protocols/oauth2/service-account
      const privateKey = await jose.importPKCS8(serviceAccount.private_key, 'RS256'); // requires HTTPS
      const jwt = await new jose.SignJWT({
        'scope': 'https://www.googleapis.com/auth/calendar.readonly'
      })
      .setProtectedHeader({ alg: 'RS256', 'typ': 'JWT', 'kid':  serviceAccount.private_key_id})
      .setSubject(CALENDAR_ACCOUNT_EMAIL) // access other account's calendar
      .setIssuer(serviceAccount.client_email)
      .setIssuedAt()
      .setAudience('https://oauth2.googleapis.com/token')
      .setExpirationTime('1h')
      .sign(privateKey);

      var resp = await fetch('https://oauth2.googleapis.com/token', {
        'method': 'POST',
        'body': new URLSearchParams({
          'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
          'assertion': jwt
        }),
        'headers': {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      var token = await resp.json() as GoogleOauth2Token;
      setGoogleOauth2Token(token);

      resp = await fetch('/engineers.txt');
      var result = Papa.parse<Engineer>(await resp.text(), {delimiter: '\t', header: true});

      setEngineers(result.data);

      var techSkills = Array.from(new Set(result.data.map((e) => e.techSkill)));
      techSkills = techSkills.sort();
      setTechSkills(techSkills);
    })();

    // Netlify Identity
    // https://github.com/netlify/netlify-identity-widget#usage

    // 현재 로그인한 netlify 사용자를 불러온다. 로그인되어 있지 않은 경우 null.
    //@ts-ignore
    setCurrentNetlifyUser(window.netlifyIdentity.currentUser);

    // netlify 로그인이 성공한 경우, react 상태에 현재 로그인한 netlify 사용자를 저장한다.
    //@ts-ignore
    netlifyIdentity.on('login', user => {
      setCurrentNetlifyUser(user);
    });


    // netlify 로그아웃이 성공한 경우, react 상태에서 현재 로그인한 netlify 사용자를 삭제한다. (null로 세팅)
    //@ts-ignore
    netlifyIdentity.on('logout', user => {
      setCurrentNetlifyUser(null);
    });

  }, []);

  const refreshEvents = useCallback(() => {
    setLoading((async() => {
      // Calendar API - Events: list
      // https://developers.google.com/calendar/api/v3/reference/events/list
      const params = new URLSearchParams({
        'timeMin': moment(startDate).toISOString(),
        'timeMax': moment(endDate).toISOString(),
        'orderBy': 'startTime',
        'singleEvents': 'true'
      });
      const resp = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?` + params.toString(), {
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': `${googleOauth2Token?.token_type} ${googleOauth2Token?.access_token}`
        }
      });

      setRefreshDatetime(moment());


      var events = await resp.json() as CalendarEvents;
      setEvents(events);

      const newBusyEngineerNames = new Set<string>();

      /// generated from regex101.com
      const regex = /\[(.+)\](.+)/gm;
      const str = events.items.map((e) => e.summary).join('\n');
      let m;

      while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }
          
          // The result can be accessed through the `m`-variable.
          // event summary: "[name1, name2] subject"
          // m[1]: "name1, name2"
          var engineerNames = m[1].split(',').map((engineerName) => engineerName.trim());

          
          engineerNames.forEach((e) => {
            newBusyEngineerNames.add(e);
          })
      }

      setBusyEngineerNames(newBusyEngineerNames);
    })());
  }, [googleOauth2Token?.access_token, googleOauth2Token?.token_type, startDate, endDate]);

  function onStartDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === '') {
      return;
    }
    var newDate = moment(event.target.value);
    setStartDate(newDate);
    if (!endDate.isAfter(newDate)) {
      setEndDate(newDate);
    }
  }

  function onEndDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === '') {
      return;
    }
    var newDate = moment(event.target.value);
    setEndDate(newDate);
    if (!startDate.isBefore(newDate)) {
      setStartDate(newDate);
    }
  }

  function formatEventRange(e: CalendarEvent) {
    // e: CalendarEvent 함수의 parameter를 불러오고 / start parameter를 참조
    // date: GoogleCalendar.ts의 Datetime 함수의 parameter 중 date? parameter를 참조
    // start date와 end date가 undefined가 아닌 값을 isEventDaily에 저장.

    const isEventDaily = e.start.date !== undefined && e.end.date !== undefined;
    const isDailyEventEndsInTheDay =
    (isEventDaily && moment(e.end.date).diff(moment(e.start.date), 'day') === 1) ||
    (!isEventDaily && moment(e.start.dateTime).format(MOMENT_DATE_FORMAT) === moment(e.end.dateTime).format(MOMENT_DATE_FORMAT));
    
    if (isEventDaily) {
      if (isDailyEventEndsInTheDay) {
        return moment(e.start.date).format(MOMENT_DATE_FORMAT);
      }

      return `${moment(e.start.date).format(MOMENT_DATE_FORMAT)} ~ ${moment(e.end.date).format(MOMENT_DATE_FORMAT)}`;
    }

    return `${moment(e.start.dateTime).format(MOMENT_DATETIME_FORMAT)} ~ ${moment(e.end.dateTime).format(isDailyEventEndsInTheDay ? MOMENT_TIME_FORMAT : MOMENT_DATETIME_FORMAT)}`;
  }

  function onSelectedTechSkillChange(e: React.ChangeEvent<HTMLInputElement>) {
    var newSet = new Set(selectedTechSkills);
    if (e.target.checked) {
      newSet.add(e.target.value);
    } else {
      newSet.delete(e.target.value);
    }
    setSelectedTechSkills(newSet);
  }

  useEffect(() => {
    setAvailableEngineers(engineers.filter((e) => {
      return selectedTechSkills.has(e.techSkill) && !busyEngineerNames.has(e.name);
    }));


    setUnavailableEngineers(engineers.filter((e) => {
      return selectedTechSkills.has(e.techSkill) && busyEngineerNames.has(e.name);
    }));
  }, [engineers, selectedTechSkills, busyEngineerNames]);

  useEffect(() => {
    if (googleOauth2Token?.token_type && googleOauth2Token?.access_token) {
      refreshEvents();
    }
  }, [googleOauth2Token?.access_token, googleOauth2Token?.token_type, refreshEvents]);
  

  //@ts-ignore
  if (currentNetlifyUser === null) {
    return<>
    </>

  }

  return (
    <>
      <div>
        <h2>조회기간</h2>
        <input type="datetime-local" title="조회기간 시작" value={`${startDate.format(MOMENT_INPUT_DATETIME_FORMAT)}`} onChange={onStartDateChange} required />
        <input type="datetime-local" title="조회기간 끝" value={`${endDate.format(MOMENT_INPUT_DATETIME_FORMAT)}`} onChange={onEndDateChange} required />
        
        <h2>솔루션</h2>
        <div className="two-columns">
        {
          techSkills.map((techSkill) => {
            return <label key={techSkill} >
              <input type="checkbox" id={`techSkill-${techSkill}`} value={techSkill} onChange={onSelectedTechSkillChange}/> {techSkill}
            </label>
          })
        }
        </div>

      </div>
      <div>
        <Async promise={loading}>
          <Async.Pending>로딩중...</Async.Pending>
          <Async.Fulfilled>
            <div>
              <button onClick={refreshEvents}>새로고침</button>
            </div>

            <h2>지원 가능 엔지니어</h2>
            <div>조회 시각: {refreshDatetime === null ? '-' : refreshDatetime.format(MOMENT_INPUT_DATETIME_FORMAT_HUMAN)}</div>
            <table>
              <thead>
                <tr>
                  <th>이름</th><th>조직</th><th>직급</th><th>솔루션</th><th>기술레벨</th>
                </tr>
              </thead>
              <tbody>
                
                { availableEngineers
                .map((e) => {
                  return <tr key={`${e.name},${e.techSkill}`}>
                    <td>{e.name}</td><td>{e.organization}</td><td>{e.title}</td><td>{e.techSkill}</td><td>{e.techLevel}</td>
                  </tr>
                })}
              </tbody>
            </table>
            { selectedTechSkills.size === 0 ? '솔루션을 선택하십시오.' : availableEngineers.length === 0 ? '지원 가능 엔지니어가 없습니다.' : ''}

            <h2>지원 불가 엔지니어</h2>
            <table>
              <thead>
                <tr>
                  <th>이름</th><th>조직</th><th>직급</th><th>솔루션</th><th>기술레벨</th>
                </tr>
              </thead>
              <tbody>
                { unavailableEngineers
                .map((e) => {
                  return <tr key={`${e.name},${e.techSkill}`}>
                    <td>{e.name}</td><td>{e.organization}</td><td>{e.title}</td><td>{e.techSkill}</td><td>{e.techLevel}</td>
                  </tr>
                })}
              </tbody>
            </table>
            { selectedTechSkills.size === 0 ? '솔루션을 선택하십시오.' : unavailableEngineers.length === 0 ? '지원 불가 엔지니어가 없습니다.' : ''}

            <h2>일정</h2>
                
            { events?.items?.length === 0 ? <>조회기간 내 일정이 없습니다.</> : ''}
            { events?.items?.length !== undefined &&  events.items.length > 0 ?
            <details open={events.items.length <= 5}>
              <summary>{events.items.length}건</summary>
              <ul>{events?.items?.map((e) => {
                return <li key={e.id}>
                  <span>{e.summary || ''}</span>&nbsp;{formatEventRange(e)}
                  </li>;
                })}
              </ul>
            </details>
            : ''}
            
          </Async.Fulfilled>
          <Async.Rejected>
            {error => <p>{error.message}<button onClick={refreshEvents}>재시도</button></p>}
          </Async.Rejected>
        </Async>
      </div>
      
    </>
  );
}

export default App;
